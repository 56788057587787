import React, { Component } from "react";

//Import CSS
import "./table.css";
import TrackingTableLine from "../../financeiro-table/tracking-table-line";
import AppContext from "../../../appContext";
import Table from "react-bootstrap/Table";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

export class TableBodyDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: props.table,
      orderedColumn: null,
      ascOrder: true,
    };
    TableBodyDropdown.contextType = AppContext;
  }
  componentDidMount(){
    this.orderByColumn("dataVencimento");
  }
  orderByColumn = column => {
    const { orderedColumn, ascOrder, table } = this.state;
    const ordemNova = orderedColumn === column ? !ascOrder : true;

    const dadosOrdenados = [...table].sort((a, b) => {
      if (a[column] < b[column]) {
        return ordemNova ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return ordemNova ? 1 : -1;
      }
      return 0;
    });

    this.setState({
      table: dadosOrdenados,
      orderedColumn: column,
      ascOrder: ordemNova,
    });

  };
  render() {
    const { orderedColumn, ascOrder } = this.state;
    return (
      <div className="tracking-order-detail">
        <div className="financeiro-order-detail-column">
          <div id="table-head-financeiro">
            <Table className="pedidos table text-center" id="pedido-table">
              <thead className="thead-dark" id="thead-table">
                <tr>
                  <th className="financeiro-table-body-dropdown-th"></th>
                  <th className="financeiro-table-body-dropdown-th"></th>
                  <th className="financeiro-table-body-dropdown-th" onClick={() => this.orderByColumn("status")}>
                    <div className="div-th-header">
                      <div className="financeiro-table-body-dropdown-th-text">status</div> <div>{ascOrder && orderedColumn === "status" ? <BsArrowUpShort size={20} /> : <BsArrowDownShort size={20} />}</div>
                    </div>
                  </th>
                  <th className="financeiro-table-body-dropdown-th" onClick={() => this.orderByColumn("nDocumento")}>
                    <div className="div-th-header">
                      <div className="financeiro-table-body-dropdown-th-text">Nº Documento</div>
                      <div>{ascOrder && orderedColumn === "nDocumento" ? <BsArrowUpShort size={20} /> : <BsArrowDownShort size={20} />}</div>
                    </div>
                  </th>
                  <th className="financeiro-table-body-dropdown-th" onClick={() => this.orderByColumn("parcela")}>
                    <div className="div-th-header">
                      <div className="financeiro-table-body-dropdown-th-text">Parcela</div> <div>{ascOrder && orderedColumn === "parcela" ? <BsArrowUpShort size={20} /> : <BsArrowDownShort size={20} />}</div>
                    </div>
                  </th>
                  <th className="financeiro-table-body-dropdown-th" onClick={() => this.orderByColumn("dataEmissao")}>
                    <div className="div-th-header">
                      <div className="financeiro-table-body-dropdown-th-text">Data Emissão</div>
                      <div>{ascOrder && orderedColumn === "dataEmissao" ? <BsArrowUpShort size={20} /> : <BsArrowDownShort size={20} />}</div>
                    </div>
                  </th>
                  <th className="financeiro-table-body-dropdown-th" onClick={() => this.orderByColumn("dataVencimento")}>
                    <div className="div-th-header">
                      <div className="financeiro-table-body-dropdown-th-text">Data Vencimento </div>
                      <div>{ascOrder && orderedColumn === "dataVencimento" || ascOrder && orderedColumn ===null ?  <BsArrowUpShort size={20} /> :<BsArrowDownShort size={20} />}</div>
                    </div>
                  </th>
                  <th className="financeiro-table-body-dropdown-th" onClick={() => this.orderByColumn("valor")}>
                    <div className="div-th-header">
                      <div className="financeiro-table-body-dropdown-th-text">Valor</div> <div>{ascOrder && orderedColumn === "valor" ? <BsArrowUpShort size={20} /> : <BsArrowDownShort size={20} />}</div>
                    </div>
                  </th>
                 {/* <th className="financeiro-table-body-dropdown-th" onClick={() => this.orderByColumn("valorAberto")}>
                    <div className="div-th-header">
                      <div className="financeiro-table-body-dropdown-th-text">Valor em aberto</div>
                      <div>{ascOrder && orderedColumn === "valorAberto" ? <BsArrowUpShort size={20} /> : <BsArrowDownShort size={20} />}</div>
                    </div>
                  </th>*/}
                </tr>
              </thead>
              {this.state.table &&
                this.state.table.map((item, index) => {
                  return (
                    <TrackingTableLine
                      classTBody = {"financeiro-main-table-body-faturas-drop"}
                      FinanceiroDetalhesModalDropdown={true}
                      key={index}
                      laboratorio_nome={this.props.info.laboratorio_nome}
                      laboratorio_cnpj={this.props.info.laboratorio_cnpj}
                      detailOrder={this.props.info.detailOrder}
                      otica_cnpj={
                        this.props.cnpj_number
                          ? this.props.cnpj_number.replace(".", "").replace(".", "").replace("-", "").replace("/", "")
                          : this.props.cnpj_number
                      }
                      item={item}
                      listaComStatus={true}
                      index={index}
                      status={item.status}
                      className={"faturas-body-dropdown"}
                    />
                  );
                })}
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default TableBodyDropdown;
