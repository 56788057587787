export async function RxPedidos(request) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      access_token: localStorage.getItem("token"),
      laboratorio: request.laboratorio,
    }),
  };
  return fetch(process.env.REACT_APP_LABS_API + "sensidia/gestao_pedidos_laboratorios/rx_pedidos.php", requestOptions)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return orderData(json, "canal", "Pedidos RX / Mês");
    });
}

export async function PedidosAtacados(request) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      access_token: localStorage.getItem("token"),
      laboratorio: request.laboratorio,
    }),
  };
  return fetch(process.env.REACT_APP_LABS_API + "sensidia/gestao_pedidos_laboratorios/atacado_pedidos.php", requestOptions)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return orderData(json, "atacado", "Pedidos Atacado");
    });
}
export async function AprovacaoCupoms(request) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      access_token: localStorage.getItem("token"),
      laboratorio: request.laboratorio,
    }),
  };
  return fetch(process.env.REACT_APP_LABS_API + "sensidia/gestao_pedidos_laboratorios/aprovacao_cupom.php", requestOptions)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return orderData(json);
    });
}

export async function orderData(json, campo, titulo) {
  const result = {};
  let chartData = [];
  if (campo === "canal") {
    const resultado = await TotalPorCanal(json);
    chartData = await DadosGraphCanal(json);
    result.canalLabel = resultado.canal;
    result.total = resultado.total;
  } else if (campo === "atacado") {
    chartData = await DadosGraph(json);
    result.total = await TotalPorLab(json);
  } else {
    chartData = await DadosGraphCupons(json);
    result.total = await TotalPorCategoria(json);
  }
  const dadosAPI = json.map(item => {
    const newItem = { ...item };
    newItem.data = new Date(item.data + "T00:00:00Z").toISOString().split("T")[0];
    return newItem;
  });
  result.dadosAPI = dadosAPI;
  result.filterData = dadosAPI;
  result.anoLabel = await anos(json);
  result.mes = await DadosMes(json);
  result.titulo = titulo;
  result.filterChartData = chartData;
  result.dataGraph = chartData;
  return result;
}

export async function anos(json) {
  const anoLabelSet = new Set();
  json.forEach(item => {
    const year = new Date(item.data + "T00:00:00Z").getUTCFullYear();
    anoLabelSet.add(year);
  });
  return Array.from(anoLabelSet).map(String);
}
export async function TotalPorLab(json) {
  let totalPedidos = 0;
  json.forEach(item => {
    totalPedidos += item.quantidade;
  });
  return totalPedidos;
}
export async function TotalPorCanal(json) {
  let totalPedidos = { Shop9: 0, Optisoul: 0, Conecta: 0 };
  const canalSet = new Set();
  json.forEach(item => {
    canalSet.add(item.canal);
    if (item.canal === "Shop9") {
      totalPedidos.Shop9 += item.quantidade;
    } else if (item.canal === "Portal Conecta") {
      totalPedidos.Conecta += item.quantidade;
    } else if (item.canal === "Optisoul") {
      totalPedidos.Optisoul += item.quantidade;
    }
  });
  return { total: totalPedidos, canal: Array.from(canalSet).map(String) };
}
export async function TotalPorCategoria(json) {
  let totalPedidos = { quantidadePedidosPromocionais: 0, quantidadeVouchersAprovados: 0 };
  const categorialSet = new Set();
  json.forEach(item => {
    categorialSet.add(item);

    totalPedidos.quantidadePedidosPromocionais += item.quantidadePedidosPromocionais;
    totalPedidos.quantidadeVouchersAprovados += item.quantidadeVouchersAprovados;
  });
  const dataGraphArray = [
    { value: totalPedidos.quantidadePedidosPromocionais, titulo: "Pedidos", cor: "002060" },
    { value: totalPedidos.quantidadeVouchersAprovados, titulo: "Aprovações", cor: "00b050" },
  ];

  return dataGraphArray.flat();
}
export async function DadosGraphCanal(json) {
  const dataGraph = await json.reduce(async (accPromise, item) => {
    const acc = await accPromise;
    const date = new Date(item.data + "T00:00:00Z");
    const year = date.getUTCFullYear();
    const month = date.toLocaleString("pt-BR", { month: "long", timeZone: "UTC" });
    const monthYear = `${month}-${year}`;

    if (!acc[monthYear]) {
      acc[monthYear] = { name: month, year, quantidade: 0 };
    }
    acc[monthYear].name = month;
    acc[monthYear][item.canal] = (acc[monthYear][item.canal] || 0) + item.quantidade;
    acc[monthYear].quantidade += item.quantidade;
    acc[monthYear].date = item.data;
    return acc;
  }, Promise.resolve({}));

  const dataGraphArray = Object.values(dataGraph);
  return dataGraphArray;
}
export async function DadosGraphCupons(json) {

  const dataGraph = await json.reduce(async (accPromise, item) => {
    const acc = await accPromise;
    const date = new Date(item.data + "T00:00:00Z");
    const year = date.getUTCFullYear();
    const month = date.toLocaleString("pt-BR", { month: "long", timeZone: "UTC" });
    const monthYear = `${month}-${year}`;
    if (!acc[monthYear]) {
      acc[monthYear] = { name: month, year, "Pedidos Promocionais": 0, Aprovações: 0 };
    }
    acc[monthYear].name = month;
    acc[monthYear]["Pedidos Promocionais"] += item.quantidadePedidosPromocionais;
    acc[monthYear]["Aprovações"] += item.quantidadeVouchersAprovados;
    acc[monthYear].date = item.data;
    return acc;
  }, Promise.resolve({}));

  const dataGraphArray = Object.values(dataGraph);
  return dataGraphArray;
}
export async function DadosGraph(json) {
  const dataGraph = await json.reduce(async (accPromise, item) => {
    const acc = await accPromise;
    const date = new Date(item.data + "T00:00:00Z");
    const year = date.getUTCFullYear();
    const month = date.toLocaleString("pt-BR", { month: "long", timeZone: "UTC" });

    const monthYear = `${month}-${year}`;
    if (!acc[monthYear]) {
      acc[monthYear] = { name: month, year, quantidade: 0 };
    }

    acc[monthYear].quantidade += item.quantidade;
    acc[monthYear].date = item.data;
    return acc;
  }, Promise.resolve({}));

  const dataGraphArray = Object.values(dataGraph);
  return dataGraphArray;
}
export async function DadosMes(json) {
  const dataGraph = await json.reduce(async (accPromise, item) => {
    const acc = await accPromise;
    const date = new Date(item.data + "T00:00:00Z");
    const year = date.getUTCFullYear();
    const month = date.toLocaleString("pt-BR", { month: "long", timeZone: "UTC" });
    const monthNumber = date.getUTCMonth()+1;
    const monthYear = `${month}-${year}`;

    acc[monthYear] = { name: month, year, mesNumber: monthNumber };

    return acc;
  }, Promise.resolve({}));

  const dataGraphArray = Object.values(dataGraph);
  return dataGraphArray;
}
