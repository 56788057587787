import React, { Component, useContext } from "react";
import AppContext from "../../appContext";
import BaseColapse from "../../components/Filtro/ColapseFilter/baseColapse";
import DataTable from "react-data-table-component";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";
import AccordionContext from "react-bootstrap/AccordionContext";
import Download from "../../components/utils/download-file/tag-a/automatic-download-user-machine/CSV";
import MultipleSelect from "../../components/selects/select-chip";
import SelectGroupMulti from "../../components/selects/select-group-multi";
import "./index.css";
class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
    };
    this.createColumnsFromData = this.createColumnsFromData.bind(this);
    this.childRefs = [];
    Table.contextType = AppContext;
  }
  cleanFilters() {
    this.props.cleanFilters();
    this.childRefs.forEach(ref => {
      if (ref?.state.personName?.length >= 1) {
        ref.handleClear();
      }
    });
  }
  createColumnsFromData(dataArray) {
    const uniqueKeys = new Set();
    const columns = [];
    if (dataArray) {
      dataArray.forEach(dataObject => {
        Object.keys(dataObject).forEach(key => {
          if (!uniqueKeys.has(key)) {
            uniqueKeys.add(key);
            columns.push({
              name: key.charAt(0).toUpperCase() + key.slice(1),
              selector: key,
              sortable: true,
              reorder: true,
            });
          }
        });
      });
    }

    return columns;
  }
  render() {
    function ContextAwareToggle({ children, eventKey, callback }) {
      const currentEventKey = useContext(AccordionContext);
      const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
      return (
        <div className="row">
          <div className="col-12 headerColapse" onClick={decoratedOnClick}>
            <div className="col-10">{children}</div>
            {currentEventKey.activeEventKey && (
              <span className="float-end" onClick={decoratedOnClick}>
                {" "}
                <Dash color="royalblue" size={24} />{" "}
              </span>
            )}
            {!currentEventKey.activeEventKey && (
              <span className="float-end" onClick={decoratedOnClick}>
                {" "}
                <Plus color="royalblue" size={24} />{" "}
              </span>
            )}
          </div>
        </div>
      );
      
    }
    const columns = this.createColumnsFromData(this.props.data);
    const actions = <Download data={this.props.data} linkName={"Exportar Lista"} className={"dashlabLink"} fileName={this.props.titulo} />;
    return (
      <>
        <BaseColapse
          divColapseFiltro={"divColapseFiltroFlex"}
          buttonName="Filtros"
          colapse={true}
          colBaseColapseButtonDisplayJustifyContent={"flex-start"}
          containerDisplay={"flex"}
          divColapseFiltroDisplay={"flex"}
          uncollapsedContainerStyle={{ display: "flex" }}
          uncollapsedDivColapseFiltroStyle={{ display: "flex" }}>
          <div className="row w-100">
            <div className="col-3 mx-auto">
              <SelectGroupMulti label="Mês" options={this.props.mes} filter={this.props.filter} ref={ref => this.childRefs.push(ref)} />
            </div>
            {this.context.userSession?.papelCategoria !== "LABORATORIO" && (
              <div className="col-3 mx-auto">
                <MultipleSelect label="Laboratório" options={this.props.laboratorio} filter={this.props.filter} ref={ref => this.childRefs.push(ref)} />
              </div>
            )}
            {this.props.origem && (
              <div className="col-3 mx-auto">
                <MultipleSelect label="Origem" options={this.props.canal} filter={this.props.filter} ref={ref => this.childRefs.push(ref)} />
              </div>
            )}
            <div className="col-3 mx-auto CleanAnchorFilter">
              <a className="CleanAnchorFilter" onClick={() => this.cleanFilters("")}>
                Limpar Filtros
              </a>
            </div>
          </div>
        </BaseColapse>

        <Accordion defaultActiveKey="1">
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="1">{this.props.titulo}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <DataTable
                  data={this.props.data}
                  dense={true}
                  pagination={true}
                  striped={true}
                  noDataComponent={<h4>Sem Resultados</h4>}
                  defaultExpanded={true}
                  columns={columns}
                  actions={actions}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }
}

export default Table;
