import React, { Component, Fragment } from "react";
import ReactLoading from "react-loading";

//Import CSS
import "./table.css";

//Import internal components
import TrackingTableLineDetail from "./tracking-table-line-details";
import FinanceiroDetalhesModal from "../financeiro-detalhes-boleto/financeiro-detalhes-modal";
import DownloadPDF from "../../components/utils/download-file/tag-a/automatic-download-user-machine/PDF";

//Import external components
import Moment from "moment";
import { Search } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import AppContext from "../../appContext";

//Import images

class TrackingTableLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      status: "",
      detailsModalActive: false,
      boletoDetails: [],
      arrow: "",
      showDrowpdown: false,
      expired: Moment() > Moment(props.item.dataVencimento) && props.item.status === "PENDENTE" ? "expired" : "",
    };

    TrackingTableLine.contextType = AppContext;

    this.handleDetailsBoleto = this.handleDetailsBoleto.bind(this);
    this.handleModalActive = this.handleModalActive.bind(this);
    this.detalhesBoletoDropdown = this.detalhesBoletoDropdown.bind(this);
    this.handleDownloadBoleto = this.handleDownloadBoleto.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
  }

  toggleDetail(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (this.state.arrow) {
        this.setState({
          showDetails: !this.state.showDetails,
          arrow: "",
        });
      } else {
        this.setState({
          showDetails: !this.state.showDetails,
          arrow: "inverse",
        });
      }
    });
  }

  handleDetailsBoleto() {
    const header = {
      client_id: localStorage.getItem("clientid"),
      access_token: localStorage.getItem("token"),
      laboratorio: this.props.laboratorio_cnpj,
      otica: this.props.otica_cnpj,
    };

    const requestOptions = {
      method: "GET",
      headers: header,
    };

    const codigoDocumento = this.props.item.codigo;
    const numeroDocumento = this.props.item.nDocumento;
    const dataEmissao = this.props.item.dataEmissao;

    this.props.FinanceiroDetalhesModalDropdown === true ? this.setState({ loading: true }) : this.context.showLoading("Buscando detalhes do boleto...");
    var status = null;
    fetch(
      process.env.REACT_APP_ESSILOR_API +
        `gestao-financeira-cliente-laboratorio/v1/faturas/${codigoDocumento}/boleto/detalhe?dataEmissao=${dataEmissao}&numeroDocumento=${numeroDocumento}`,
      requestOptions,
    )
      .then(response => {
        status = response.status;
        return response.json();
      })
      .then(json => {
        if (json && status === 200) {
          if (json.length > 0) {
            this.setState({ boletoDetails: json });
            this.handleModalActive();
          } else {
            Swal.fire({
              html: "Não foi possível recuperar as informações do boleto selecionado.",
              width: "600px",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          }
        } else if (status === 404) {
          Swal.fire({
            html: json[0].mensagem,
            width: "600px",
            height: "600px",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        } else {
          Swal.fire({
            html: "Erro interno.",
            width: "600px",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        }
        this.props.FinanceiroDetalhesModalDropdown === true ? this.setState({ loading: false }) : this.context.hideLoading();
      });
  }

  handleModalActive() {
    this.setState({ detailsModalActive: !this.state.detailsModalActive });
  }
  detalhesBoletoDropdown() {
    if (!this.state.showDrowpdown) {
      this.setState({ showDrowpdown: true });
    } else {
      this.setState({ showDrowpdown: false });
    }
  }

  handleDownloadBoleto() {
    const boletoPDF = document.getElementById("boletoPDFComponent");

    const openPdfInNewTab = () => {
      if (boletoPDF.src) {
        window.open(boletoPDF.src, "_blank");
      }
    };

    boletoPDF.addEventListener("load", () => {
      openPdfInNewTab();
    });

    const header = {
      client_id: localStorage.getItem("clientid"),
      access_token: localStorage.getItem("token"),
      laboratorio: this.props.laboratorio_cnpj,
      otica: this.props.otica_cnpj,
    };

    const requestOptions = {
      method: "GET",
      headers: header,
    };

    const codigoDocumento = this.props.item.codigo;
    const dataEmissao = this.props.item.dataEmissao;
    var status = null;
    this.context.showLoading("Buscando boleto...");
    fetch(
      process.env.REACT_APP_ESSILOR_API + `gestao-financeira-cliente-laboratorio/v1/faturas/${codigoDocumento}/boleto?dataEmissao=${dataEmissao}`,
      requestOptions,
    )
      .then(response => {
        this.context.hideLoading();
        status = response.status;
        return response.json();
      })
      .then(json => {
        if (status === 200 && json && json.boleto != "Registro n\u00e3o encontrado no laborat\u00f3rio") {
          const byteArray = Uint8Array.from(
            atob(json.boleto)
              .split("")
              .map(char => char.charCodeAt(0)),
          );
          var pdfFile = new Blob([byteArray], {
            type: "application/pdf",
          });
          const pdfURL = URL.createObjectURL(pdfFile);
          boletoPDF.src = pdfURL;
        } else if (status === 404) {
          Swal.fire({
            html: json[0].mensagem,
            width: "600px",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        } else {
          Swal.fire({
            html: "Boleto não encontrado.",
            width: "600px",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          }).then(result => {});
        }
      });
  }

  render() {
    const { item } = this.props;
    const expired = new Date() > new Date(item.dataVencimento) && item.status !== "Pago" && this.props.status !== "PAGO" ? "expired" : "";
    const classTd = this.props?.className ? this.props?.className : "financeiro-table-td";
    const url = `gestao-financeira-cliente-laboratorio/v1/faturas/${this.props.item.codigo}/boleto?dataEmissao=${this.props.item.dataEmissao}`;
    const NFMessage = `Boleto_N°_${this.props.item.nDocumento}_Data_De_Emissao_${this.props.item.dataEmissao}.pdf`;
    var classeTBody = Boolean(this.props.classTBody) === false ? "tracking-main-table-body" : this.props.classTBody;
    return (
      <>
        <tbody className={classeTBody} key={this.props.index}>
          <tr data-result={this.props.index} onClick={this.toggleDetail}>
            <td className={classTd}>
              <div className={classTd + " div "}>
                <a title="Ver detalhes do Boleto" onClick={this.handleDetailsBoleto}>
                  <span>
                    <Search size={24} onClick={this.detalhesBoletoDropdown} />
                  </span>
                </a>
              </div>
            </td>

            {!this.props.FinanceiroDetalhesModalDropdown && (
              <FinanceiroDetalhesModal
                laboratorio_nome={this.props.laboratorio_nome}
                active={this.state.detailsModalActive}
                handleModal={this.handleModalActive}
                itens={this.state.boletoDetails}
                laboratorio={this.props.laboratorio_cnpj}
                otica={this.props.otica_cnpj}
              />
            )}

            {this.props.status !== "Pago" && this.props.status !== "PAGO" ? (
              <td className={classTd}>
                <div className={classTd + " div "}>
                  <DownloadPDF
                    UpcScam={true}
                    lab={this.props.laboratorio_cnpj}
                    otica={this.props.otica_cnpj}
                    messageErro={false}
                    archiveName={NFMessage}
                    url={url}
                    linkName={this.props.item.codigo}
                  />
                </div>
              </td>
            ) : (
              <td className={`${classTd} ${expired}`}>
                <div className={classTd + " div "}></div>
              </td>
            )}
            {this.props.listaComStatus && (
              <td className={"financeiro-table-td-status"}>
                <div class="financeiro-fatura-financeiro-table-td-status-dropdown">
                  <p data-status={this.props.status}>{this.props.item.status}</p>
                </div>
              </td>
            )}
            <td className={`${classTd}  ${expired}`}>
              {this.props.item.nDocumento !== "" && typeof this.props.item.nDocumento !== "undefined" ? this.props.item.nDocumento : "-"}
            </td>
            <td className={`${classTd}  ${expired}`}>{this.props.item.parcela}</td>
            <td className={`${classTd} ${expired}`}>{this.props.item.dataEmissao && Moment(this.props.item.dataEmissao).format("DD/MM/YYYY")}</td>
            <td className={`${classTd} ${expired}`}>{this.props.item.dataVencimento && Moment(this.props.item.dataVencimento).format("DD/MM/YYYY")}</td>
            <td className={`${classTd} ${expired}`}>
              {parseFloat(this.props.item.valor ? this.props.item.valor : 0).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </td>
            {/* <td className={`${classTd} ${expired}`}>
              {parseFloat(this.props.item.valorAberto ? this.props.item.valorAberto : 0).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </td>*/}
            <td className={`${classTd} ${expired}`}>{this.props.item.empresa !== "" ? this.props.item.empresa : "-"}</td>
          </tr>

          {this.props.FinanceiroDetalhesModalDropdown && this.state.showDrowpdown == true && (
            <tr>
              <td colSpan="8">
                {this.state.loading === true ? (
                  <ReactLoading type="bubbles" color="#00518b" height={65} width={65} className="rounded mx-auto d-block" />
                ) : (
                  <FinanceiroDetalhesModal
                    laboratorio_nome={this.props.laboratorio_nome}
                    active={this.state.detailsModalActive}
                    handleModal={this.handleModalActive}
                    itens={this.state.boletoDetails}
                    laboratorio={this.props.laboratorio_cnpj}
                    otica={this.props.otica_cnpj}
                    dropdown={true}
                    classNameContainerTable={"classNameContainerTable"}
                  />
                )}
              </td>
            </tr>
          )}
        </tbody>
      </>
    );
  }
}

export default TrackingTableLine;
