import React, { Component } from "react";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from "recharts";
import "../index.css";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
class SimpleBarGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedB: false,
    };

    this.getMidMonthPerYear = this.getMidMonthPerYear.bind(this);
  }

  getMidMonthPerYear(data) {
    const groupedByYear = data.reduce((acc, item) => {
      if (!acc[item.year]) {
        acc[item.year] = [];
      }
      acc[item.year].push(item);
      return acc;
    }, {});

    const midMonths = Object.keys(groupedByYear).reduce((acc, year) => {
      const months = groupedByYear[year];
      const midIndex = Math.floor(months.length / 2);
      const midMonthDate = new Date(months[midIndex].date);
      const midMonthIndex = midMonthDate.getUTCMonth() + 1;
      acc[year] = {
        startMonth: new Date(months[0].date).getUTCMonth() + 1,
        midMonth: midMonthIndex,
      };
      return acc;
    }, {});

    return midMonths;
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  render() {
    const monthTickFormatter = tick => {
      const date = new Date(tick + "T00:00:00Z");
      return date.toLocaleString("pt-BR", { month: "short", timeZone: "UTC" });
    };

    const renderQuarterTick = tickProps => {
      const delimita = this.getMidMonthPerYear(this.props.data);
      const { x, y, payload } = tickProps;
      const { value, offset } = payload;
      const date = new Date(value + "T00:00:00Z");
      const ano = date.getUTCFullYear();
      const mes = date.getUTCMonth() + 1;
      if (mes == delimita[ano].midMonth) {
        return <text x={x} y={y - 2} textAnchor="middle">{`${ano}`}</text>;
      }
      if (mes == delimita[ano].startMonth) {
        const pathX = Math.floor(x - offset) + 0.5;

        return <path d={`M${pathX},${y - 4}v${-35}`} stroke="#888585" strokeWidth="1" />;
      }
      return null;
    };
    const labelStyle = {
      fill: "white",
      stroke: "black",
      strokeWidth: 1,
      fontWeight: "bold",
    };
    return (
      <ResponsiveContainer width="100%"  id="responsiveContainer" height={400}>
        <div>
          <FormGroup row>
            <FormControlLabel control={<Switch checked={this.state.checkedB} onChange={this.handleChange} name="checkedB" color="primary" />} label="Dados" />
          </FormGroup>
        </div>
        <div className="graph-titulo">
          <p>{this.props.titulo}</p>
        </div>
        <BarChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={monthTickFormatter} />
          <XAxis dataKey="date" axisLine={false} tickLine={false} interval={0} tick={renderQuarterTick} height={1} scale="band" xAxisId="quarter" />
          <YAxis />
          <Tooltip />
          <Bar
            dataKey="quantidade"
            fill="#00518b"
            activeBar={<Rectangle fill="#006ab4" stroke="blue" />}>
            {this.state.checkedB && <LabelList dataKey="quantidade" position="Top" style={labelStyle} />}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
export default SimpleBarGraph;
