import React, { Component } from "react";
import "./home.css";
import Welcome from "./home-welcome";
import CarouselConecta from "./carousel-conecta";
import CarouselEssilor from "./carousel-essilor";
import AppContext from "../appContext";
import Swal from "sweetalert2";
import { Redirect } from "react-router-dom";
import { redirectPage } from "../components/utils/redirectpage";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categorias: [],
      nomeProprietario: "",
      especialistaPremiere: "",
    };

    Home.contextType = AppContext;
  }

  componentDidMount() {
    redirectPage(this.context.userSession, "/home");
    this.context.showLoading("Carregando módulos...");

    this.getReferencial(this.getModulesCategorias);
    window.localStorage.removeItem("@labatacado_hasLaboratorio");
    window.localStorage.removeItem("@labatacado_lab");
    window.localStorage.removeItem("@labatacado_otica");
    window.localStorage.removeItem("@labatacado_emp");
    window.localStorage.removeItem("@labatacado_emp_cnpj");
    window.localStorage.removeItem("emailLogin");
  }

  getModulesCategorias() {
    var cat = [];
    var modulos = [];

    var modulosAux = this.isEspecialistaPremiere(this.context.userSession.modulos);
    // if (process.env.REACT_APP_GA_ID !== "dev") {
    //   modulosAux = modulosAux.filter((item, i) => {
    //     return item !== "Financeiro";
    //   });
    // }
    modulos = modulosAux;
    var especialista = this.context.especialistaPremiere && this.context.especialistaPremiere.includes("VARILUX") ? true : false;

    const requestOptions1 = {
      method: "POST",
      body: JSON.stringify({
        modulos: modulos,
        especialista: especialista,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_modules_categories.php", requestOptions1)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === 200 && json.items) {
          json.items.map((item, index) => {
            var mods = [];
            var catAux = {
              nome: item.RecordName,
              id: item.Id,
              ordem: item.Posicao_c,
            };

            item.modulos.map((items, index) => {
              let mensagemNovidade = null;
              let dataLimiteNovidade = null;
              if (items.MensagemNovidade_c && items.MensagemNovidade_c != undefined) {
                mensagemNovidade = items.MensagemNovidade_c;
              } else if (items.MensagemDeNovidade_c && items.MensagemDeNovidade_c != undefined) {
                mensagemNovidade = items.MensagemDeNovidade_c;
              }
              if (items.DataLimiteNovidade_c && items.DataLimiteNovidade_c != undefined) {
                dataLimiteNovidade = items.DataLimiteNovidade_c;
              } else if (items.DataLimiteDaNovidade_c && items.DataLimiteDaNovidade_c != undefined) {
                dataLimiteNovidade = items.DataLimiteDaNovidade_c;
              }

              var modAux = {
                nome: items.RecordName,
                pos: items.Posicao_c,
                url: items.URL_c,
                tab: items.NovaAba_c,
                logo: items.URL_Logo_c,
                message: items.Imagem_c,
                categoria: items.Categoria_mod_c,
                sso: items.SSO_c,
                piloto: items.OticasPilotos_c,
                mensagemNovidade: mensagemNovidade,
                dataLimiteNovidade: dataLimiteNovidade,
              };

              if (!items.OticasPilotos_c) {
                mods.push(modAux);
              } else {
                const contas = items.OticasPilotos_c.split(";");
                if (contas.some(elem => elem.includes(this.context.userSession.conta))) {
                  mods.push(modAux);
                }
              }
            });

            mods.sort(this.compare);
            catAux.modulos = mods;

            cat.push(catAux);
          });

          this.setState({ categorias: cat }, () => {
            this.state.categorias.sort(function (a, b) {
              return a.ordem - b.ordem;
            });
            this.setState({ isReady: true }, () => {
              this.context.hideLoading();
              this.isUsuarioSemCnpj();
              this.isNovoUsuarioOptica();
            });
          });
        } else {
          this.setState({ isReady: true }, () => {
            this.context.hideLoading();
            this.isUsuarioSemCnpj();
            this.isNovoUsuarioOptica();
          });
        }
      });
  }

  getReferencial(callback) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cnpj: this.context.userSession.conta }),
    };
    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.setState(
          {
            nomeProprietario: json.nome,
          },
          callback,
        );
      });
  }

  async isNovoUsuarioOptica() {
    var mensagemUserOptica = "Solicite a alteração do seu perfil de acesso entrando em contato com seu Consultor Essilor";
    var mensagemUserLab = mensagemUserOptica;
    var mensagemUserEssilor = mensagemUserOptica;

    mensagemUserOptica = "Solicite a alteração do seu perfil de acesso entrando em contato com seu Consultor Essilor";
    mensagemUserLab = "Solicite a alteração do seu perfil de acesso entrando em contato através do email <strong>suporte.conecta@essilor.com.br<strong/>";
    mensagemUserEssilor = "Solicite a alteração do seu perfil de acesso entrando em contato através do email <strong>suporte.conecta@essilor.com.br<strong/>";

    if (
      this.context.userSession.papel === "Novo Usuário Óptica" ||
      this.context.userSession.papel === "Novo Usuário Laboratório" ||
      this.context.userSession.papel === "Novo Usuário Lab Independente" ||
      this.context.userSession.papel === "Novo Usuário Essilor"
    ) {
      this.context.showLoading("Carregando módulos...");
      await fetch(process.env.REACT_APP_LABS_API + "get_users.php", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("session_token"),
        }),
        body: JSON.stringify({ cnpj: this.context.userSession.conta }),
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          console.log(json )
          let response = json.items.filter((item, index, self) => self.findIndex(i => i.email === item.email) === index);
          if (this.context.userSession.papel === "Novo Usuário Óptica" || this.context.userSession.papel === "Novo Usuário Lab Independente") {
            if (response.filter(el => el.papel && el.papel.includes("Proprietário") || el.papel.includes("Gerente") && el.inativo === false).length > 0) {
              var mensagem = "<br/>Solicite ao(s) seguinte(s) usuário(s) para alterar seu perfil de acesso:<br/><br/>";
              response.map(elem => {
              if ((elem.papel && elem.papel.includes("Proprietário") || elem.papel.includes("Gerente") && elem.inativo === false)) {
                  mensagem += `<strong>${elem.nome} - ${elem.email}</strong><br/>`;
                }
              });
              this.setState({
                novoUsuarioMensagem: mensagem,
              });
            } else {
              this.setState({
                novoUsuarioMensagem: `<br/>${mensagemUserOptica}.<br/>`,
              });
            }
          } else if (this.context.userSession.papel === "Novo Usuário Laboratório") {
            if (response.filter(el => el.papel && el.papel && el.papel.includes("Gerente") && el.inativo === false).length > 0 ) {
              var mensagem = "<br/>Solicite ao(s) seguinte(s) usuário(s) para alterar seu perfil de acesso:<br/><br/>";
              response.map(elem => {
                if (elem.papel && elem.papel.includes("Gerente") && elem.inativo === false) {
                  mensagem += `<strong>${elem.nome} - ${elem.email}</strong><br/>`;
                }
              });
              this.setState({
                novoUsuarioMensagem: mensagem,
              });
            } else {
              this.setState({
                novoUsuarioMensagem: `<br/>${mensagemUserLab}.<br/>`,
              });
            }
          } else {
            this.setState({
              novoUsuarioMensagem: `<br/>${mensagemUserEssilor}.<br/>`,
            });
          }
        });

      this.context.hideLoading();

      Swal.fire({
        title: "Atenção",
        html:
          "Você é um novo usuário na empresa <strong>" +
          this.state.nomeProprietario +
          "</strong> e, por isso, seu acesso aos módulos é restrito.<br/>" +
          this.state.novoUsuarioMensagem,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        scrollbarPadding: false,
      }).then(result => {
        if (result.value) {
        }
      });
    }
  }

  isEspecialistaPremiere(modulos) {
    if (this.context.especialistaPremiere === "VARILUX ESPECIALISTA PREMIER" || this.context.especialistaPremiere === "VARILUX ESPECIALISTA PLATINE") {
      return modulos;
    } else if (this.context.userSession.conta === "30260871000105" && this.context.userSession.papel === "Dev") {
      return modulos;
    } else {
      var modulosAux = modulos.filter((item, i) => {
        return item !== "Relacionamento Digital";
      });
      return modulosAux;
    }
  }

  isUsuarioSemCnpj() {
    if (
      localStorage.getItem("isUsuarioSemCnpj") === "true" &&
      this.state.isReady &&
      !this.context.userSession.conta &&
      (this.context.userSession.papel.includes("Usuário não associado a"))
    ) {
      localStorage.setItem("isUsuarioSemCnpj", false);
      Swal.fire({
        title: "Atenção",
        text: "Você não está associado a nenhuma empresa no momento. Gostaria de atualizar esta informação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(result => {
        if (result.value) {
          this.setState({ redirectProfile: true });
        }
      });
    }
  }

  compare = (a, b) => {
    if (a.pos < b.pos) {
      return -1;
    }
    if (a.pos > b.pos) {
      return 1;
    }
    return 0;
  };

  render() {
    if (this.state.isReady) {
      if (this.state.redirectProfile) {
        return <Redirect to="/profile" />;
      }

      return (
        <div className="x-body" id="main-body">
          <main className="x-main homelog">
            <div className="x-main__first-container">
              <Welcome></Welcome>
              {this.state.categorias.map((item, index) => {
                return item.modulos && item.modulos.length > 0 && <CarouselEssilor key={index} categoria={item.nome} id={item.id} modulos={item.modulos} />;
              })}
            </div>
            <div className="x-main__second-container">
              <CarouselConecta></CarouselConecta>
            </div>
          </main>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default Home;
