import React, { Component } from "react";

//Import CSS
import "./table.css";

//Import internal components
import AppContext from "../../appContext";

//Import external components
import Moment from "moment";
import Table from "react-bootstrap/Table";
import TrackingTableLineDetailCard from "./tracking-table-line-details-card";

//Import images

export class TrackingTableLineDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detalhes: [],
      submitted: false,
      loading: false,
    };

    TrackingTableLineDetail.contextType = AppContext;
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.context.showLoading("");
    var table = document.getElementById("financeiro-tabela");
    table.classList.add("financeiro-tabela-opacity");
    const requestOptions = {
      method: "GET",
      headers: {
        access_token: localStorage.getItem("token"),
        laboratorio: this.props.laboratorio_cnpj,
        otica: this.props.otica_cnpj,
      },
    };

    let urlParams = this.props.pedido_laboratotio;

    fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/pedidos/" + urlParams + "/status", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        json.sort((a, b) => new Moment(a.data).format("X") - new Moment(b.data).format("X"));
        this.setState({
          detalhes: json,
        });
        this.setState({
          loading: false,
        });
        this.context.hideLoading();
        table.classList.remove("financeiro-tabela-opacity");
      });
  }

  render() {
    return (
      <div className="tracking-order-detail">
        <div className="tracking-order-detail-column esquerda">
          <TrackingTableLineDetailCard
            laboratorio_cnpj={this.props.laboratorio_cnpj}
            otica_cnpj={this.props.otica_cnpj}
            pedido_laboratotio={this.props.pedido_laboratotio}
            nome_consumidor={this.props.nome_consumidor}
            detailOrder={this.props.detailOrder}
            pedido_otica={this.props.pedido_otica}
            data_faturamento={this.props.data_faturamento}
            produto_direito={this.props.produto_direito}
            produto_esquerdo={this.props.produto_esquerdo}
            data_prevista={this.props.data_prevista}
            nota_fiscal={this.props.nota_fiscal}
            laboratorio_nome={this.props.laboratorio_nome}
            tipoPedido={this.props.tipoPedido}
          />
        </div>

        <div className="tracking-order-detail-column direita">
          <div className="timeline-container">
            {this.state.detalhes.map((item, index) => {
              return (
                <div className="timeline-item">
                  <div className="timeline-item-content">
                    <span className="tag">{item.descricao}</span>
                    <time>{item.data && Moment(item.data).format("DD/MM/YYYY HH:mm")}</time>
                    <p>{item.observacao !== "" && typeof item.observacao !== "undefined" ? item.observacao : "Sem observação"}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default TrackingTableLineDetail;
