import React, { Component } from 'react'
import PDFOrderP from './pdf-order-p';

class PDFOrderArmacao extends Component {

    render() {
        const { armacao } = this.props;

        return (
            <div className="pdf-order-armacao row">
                <div className="col-12">
                    <h1 className="pdf-title">Dados da armação</h1>
                </div>
                <div className="col-6">
                    <PDFOrderP label='Tipo de armação' value={armacao ? armacao.tipo_armacao : ''} />
                    <PDFOrderP label='Modelo da armação' value={armacao ? armacao.modelo_armacao : ''} />
                    <PDFOrderP label='Referência da Armação' value={armacao ? armacao.referencia_armacao : ''} />
                    <PDFOrderP label='Observação' value={armacao ? armacao.observacao : ''} />

                </div>
                <div className='col-6'>
                    <PDFOrderP label='Vertical' value={armacao ? armacao.vertical : ''} />
                    <PDFOrderP label='Horizontal' value={armacao ? armacao.horizontal : ''} />
                    <PDFOrderP label='Diagonal maior' value={armacao ? armacao.diagonal_maior : ''} />
                    <PDFOrderP label='Ponte' value={armacao ? armacao.ponte : ''} />
                </div>
            </div>
        )
    }
}

export default PDFOrderArmacao