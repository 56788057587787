import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import DownloadCSVAncora from "../../../components/utils/download-file/tag-a/automatic-download-user-machine/CSV";
import AppContext from "../../../appContext";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
//Import CSS
import "./table.css";

//Import internal components
import TableBody from "./table-body";

export class tableHead extends Component {
  constructor(props) {
    super();

    this.state = {
      data_tabela: [],
      table: props.data,
      pageCount: Math.ceil(props.data?.length / 10),
      offset: 0,
      orderedColumn: null,
      ascOrder: true,
      tableAux: props.data,
      key: Date.now(),
      changed: 0
    };
    tableHead.contextType = AppContext;
    this.paginateData = this.paginateData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.paginateData();
  }

  paginateData() {
    const dataPagination = this.state?.tableAux?.slice(this.state?.offset, this.state.offset + 10);
    this.setState(prevState => ({ data_tabela: dataPagination, changed: prevState.changed + 1 }));
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * 10;

    this.setState({ offset: offset, data_tabela: [] }, this.paginateData);
  };
  orderByColumn = column => {
    const { orderedColumn, ascOrder, tableAux } = this.state;

    const ordemNova = orderedColumn === column ? !ascOrder : true;

    const dadosOrdenados = [...tableAux].sort((a, b) => {
      if (a[column] < b[column]) {
        return ordemNova ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return ordemNova ? 1 : -1;
      }
      return 0;
    });

    this.setState(
      {
        offset: 0,
      },
      () => {
        const dataPagination = dadosOrdenados.slice(this.state.offset, this.state.offset + 10);
        this.setState(prevState => ({
          data_tabela: dataPagination,
          tableAux: dadosOrdenados,
          orderedColumn: column,
          ascOrder: ordemNova,
          key: prevState.key + 1,
          changed: prevState.changed + 1
        }));
      },
    );
  };

  render() {
    const { orderedColumn, ascOrder } = this.state;
    return (
      <>
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próximo"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            key={this.state.key}
          />
        </div>
        <div className="continer-financeiro-table">
          {this.state.data_tabela && (
            <Table className="financeiro-faturas-table table text-center">
              <thead className="financeiro-faturas-table-head">
                <tr>
                  {this.props.headers.map((header, index) => (
                    <th className="financeiro-faturas-table-head-th" key={index} onClick={() => this.orderByColumn(header)}>
                      <div className="div-th-header">
                        <div className="financeiro-faturas-head-th-text">{header}</div>
                        <div>{ascOrder && orderedColumn === header ? <BsArrowUpShort size={20} /> : <BsArrowDownShort size={20} />}</div>
                      </div>
                    </th>
                  ))}
                  <th className="financeiro-faturas-table-head-th"></th>
                </tr>
              </thead>
              {this.props.data && (
                <TableBody
                  tablePaginate={this.state.data_tabela}
                  table={this.props.data}
                  info={this.props.info}
                  cnpj_number={this.props.cnpj_number}
                  paginate={this.state.offset}
                  changed  = {this.state.changed}
                />
              )}
            </Table>
          )}
        </div>
      </>
    );
  }
}

export default tableHead;
